
import FiltroDataModal from "@/views/agenda-virtual/components/modais/agendamentos/components/FiltroDataModal.vue";
import AdicionarServicoModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoModal.vue";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import AdicionarServicoPassanteModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoPassanteModal.vue";

import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import moment, { Moment } from "moment";
import { Modal } from "bootstrap";
import { ContentLoader } from "vue-content-loader";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ConcessionariaModel } from "@/models/ConcessionariaModel";
import { OsAbertaVeiculoPassante, OsAbertaVeiculoAgendados } from "@/models/AgendaModel";
import StatusChecklist from '@/views/agenda-virtual/components/status/StatusChecklist.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "new-event-modal",
  components: {
    FiltroDataModal,
    AdicionarServicoModal,
    AdicionarServicoPassanteModal,
    VisualizarServicoModal,
    EditarServicoModal,
    ContentLoader,
    StatusChecklist
  },
  emits: ["success"],
  setup() {
    const store = useStore();
    const initialForm = ref();
    const loading = ref(false);
    const refModalNew: any = ref(null);
    const dataMesAtual = moment().startOf("day");
    const dataMesAtualFormatada = dataMesAtual
      .locale("pt-br")
      .format("DD MMMM - YYYY")
      .replaceAll("-", "de");
    const dataFiltrada = ref(dataMesAtualFormatada);
    const concessionariaInfo = ref<ConcessionariaModel>();
    const scheduledVehicles = ref<OsAbertaVeiculoAgendados[]>([]);
    const passingVehicles = ref<OsAbertaVeiculoPassante[]>([]);

    onMounted(() => {
      refModalNew.value = new Modal(
        document.getElementById("kt_modal_add_event_agendamento-modal-os-aberta")
      );
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      osAberta()
    });

    store.watch(
      () => store.getters.concessionariaSelectedInfo,
      () => {
        concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      },
      { deep: true }
    );

    store.watch(
      () => store.getters.baseVeiculosOsAberta,
      () => {
        osAberta()
      },
      { deep: true }
    );

    const osAberta = () => {
       loading.value = true;
        passingVehicles.value = store.getters.baseVeiculosOsAberta.passante ?? [];
        scheduledVehicles.value = store.getters.baseVeiculosOsAberta.agendado ?? [];
        loading.value = false;
        
    }

    function openModalServicosAgendados() {
      const modal = new Modal(
        document.getElementById("kt_modal_select_agendamento_realizados")
      );
      modal.show();
    }

    function closeModalServicosAgendados() {
      const modal = document.getElementById("kt_modal_select_agendamento_realizados");
      hideModal(modal);
    }

    async function emiteSuccess() {
      loading.value = true;
      await Promise.all([store.dispatch(Actions.SET_OS_ABERTA)]);
      store.dispatch(Actions.SET_BASE_SERVICOS)
      loading.value = false;
    }

    function newTaskScheduled(
      agendamento: OsAbertaVeiculoAgendados,
      selectedOs: number | string
    ) {
      if (concessionariaInfo.value) {
        initialForm.value = {
          codAgendamento: agendamento.idagendamento.toString(),
          detalhes: agendamento.obs,
          taskType: "",
          cliente: agendamento.cliente,
          placa: agendamento.placa,
          chassi: agendamento.chassi,
          taskHourEntrega: "",
          taskHourRecepcao: moment(agendamento.dataRecepcaoChecklist).format("HH:mm"),
          formType: "New",
          taskEndHour: "",
          taskDateTratada: agendamento.dataagendamento,
          taskDate: moment(agendamento.dataagendamento, "DD/MM/YYYY"),
          taskStartHour: "",
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          dataAgendamento: `${agendamento.dataagendamento} ${agendamento.horaagendada}`,
          marca: agendamento.marca,
          modelo: agendamento.modelo,
          consultorAgendado: agendamento.consultor_agendado,
          consultorAgendadoTratado: agendamento.consultor_agendado,
          osAberta: selectedOs,
          aguarda: agendamento.aguardaChecklist
        };
      }

      refModalNew.value.show();
    }

    async function newTaskServicePassing(
      service: OsAbertaVeiculoPassante,
      selectedOs: number | string
    ) {
      if (concessionariaInfo.value) {
        initialForm.value = {
          taskType: "",
          detalhes: "",
          codAgendamento: null,
          cliente: service.nomeCliente,
          placa: service.placa,
          chassi: service.chassi,
          marca: service.marca,
          taskHourEntrega: "",
          taskHourRecepcao: moment(service.dataRecepcao).format("HH:mm"),
          taskDateTratada: moment(service.dataRecepcao).format("DD/MM/YYYY"),
          taskDate: service.dataRecepcao,
          formType: "New",
          taskEndHour: "",
          taskStartHour: "",
          cnpjConcessionaria: concessionariaInfo.value.cnpj,
          codConcessionaria: concessionariaInfo.value.codConcessionaria,
          dataAgendamento: moment(service.dataRecepcao).format("DD/MM/YYYY HH:mm"),
          modelo: service.modelo,
          consultorAgendado: service.intConsultor,
          consultorAgendadoTratado: service.intConsultor,
          osAberta: selectedOs,
          aguarda: service.aguarda
        };
      }

      refModalNew.value.show();
    }

    const selectNumberOsScheduled = async (scheduled : OsAbertaVeiculoAgendados)  => {

      const allOs = scheduled.osAberta.map((osAberta) => osAberta.nos)
      const allOsFormat = allOs.reduce((acc, os) => {
        acc[os] = os;
        return acc;
      }, {});

      if (allOs.length > 1) {
        try {
          const selectedOs = await swalSelect(allOsFormat,'Este veículo agendado possui duas Ordens de Serviço.')
          if (selectedOs) {
            newTaskScheduled(scheduled, selectedOs);
          }
        } catch (error) {
          console.error("Erro ao selecionar a Ordem de Serviço:", error);
        }
      } else {
        newTaskScheduled(scheduled, allOs[0]);
      }
    }   

    const selectNumberOsPassing = async (passing : OsAbertaVeiculoPassante)  => {

      const allOs = passing.osAberta.map((osAberta) => osAberta.nos)
      const allOsFormat = allOs.reduce((acc, os) => {
        acc[os] = os;
        return acc;
      }, {});

      if (allOs.length > 1) {
        try {
          const selectedOs = await swalSelect(allOsFormat,'Este veículo passante possui duas Ordens de Serviço.')

          if (selectedOs) {
            newTaskServicePassing(passing, selectedOs);
          }
        } catch (error) {
          console.error("Erro ao selecionar a Ordem de Serviço:", error);
        }
      } else {
        newTaskServicePassing(passing, allOs[0]);
      }
    }

    const swalSelect = async (object : object,title: string) : Promise<string> => {
      const { value: selectedOs } = await Swal.fire({
            title: title,
            input: "select",
            inputOptions: object,
            inputPlaceholder: "Selecione uma Ordem de Serviço",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return "Você precisa selecionar uma Ordem de Serviço!";
              }
              return null;
            }
          });
      
      return selectedOs
    }  

    return {
      closeModalServicosAgendados,
      openModalServicosAgendados,
      initialForm,
      moment,
      refModalNew,
      newTaskScheduled,
      emiteSuccess,
      dataFiltrada,
      loading,
      passingVehicles,
      scheduledVehicles,
      newTaskServicePassing,
      selectNumberOsPassing,
      selectNumberOsScheduled,
    };
  },
});


import { defineComponent,ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ContentLoader } from 'vue-content-loader';
import { useStore } from "vuex";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import { ServicoRecepcao } from "@/models/AgendaModel";

export default defineComponent({
    name: "ServicoParalizadoModal",
    components: {
        ContentLoader,
        EditarServicoModal
    },
    emits: ['success'],
    setup() {
        const store = useStore();
        const loading = ref(false);
        const veiculosRecepcao = ref<ServicoRecepcao[]>(store.getters.baseVeiculosRecepcao)
        const dataVeiculos = ref<ServicoRecepcao[]>(store.getters.baseVeiculosRecepcao)
        const statusServico = ref([
            { status: "Aguardando Serviço", value: "1" },
            { status: "Em Serviço", value: "2" },
            { status: "Aguardando Inspeção", value: "3" },
            { status: "Aguardando Entrega", value: "4" },
            { status: "Todos", value: "5" },
        ])
        const filterSelect = ref("5")

        store.watch(() => store.getters.baseAgendamentosList, () => {
            veiculosRecepcao.value = store.getters.baseVeiculosRecepcao
            dataVeiculos.value = store.getters.baseVeiculosRecepcao
        }, { deep: true });

        const filterStatus = (): void => {
            dataVeiculos.value = veiculosRecepcao.value.filter(veiculo => {
                if (filterSelect.value == '5') {
                    return veiculo
                }
                return veiculo.progressoOS == filterSelect.value
            })
        }

        return {        
            loading,
            veiculosRecepcao,
            filterSelect,
            statusServico,
            filterStatus,
            dataVeiculos
        };
    },
});

const tarefas = [
    {
        text: "TESTE",
        value: 1,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "#682E21",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "RET (Retorno)",
        value: 2,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "black",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "AL (Alinhamento)",
        value: 3,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "orange",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "DIAG (Diagnóstico)",
        value: 4,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "red",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "QS (Quick Service)",
        value: 5,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "#1F3059",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "MP (Manutenção Periódica)",
        value: 6,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "#darkgreen",
            headerTextColor: "#ffffff"
        },
    },
    {
        text: "RG (Reparo Geral)",
        value: 7,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "lawngreen",
            headerTextColor: "#000000"
        },
    },
    {
        text: "AC (Acessórios)",
        value: 8,
        config: {
            hasContent: true,
            hasFooter: true,
            bgColor: "yellow",
            headerTextColor: "#ffffff"
        },
    },
];

export { tarefas }

import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch, nextTick } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { TODAY } from "@/core/data/events";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import AdicionarServicoPassanteModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoPassanteModal.vue";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import { ContentLoader } from 'vue-content-loader';
import { Modal } from "bootstrap";
import moment from "moment";
import { editAgendamento, getBaseAgendamento, getServicosAgendados, orcamentoComplementarTotal } from '@/services/AgendaService';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { config } from "@/views/agenda-virtual/ConfigEvents";
import useAlert from "@/composables/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useEmitter from '@/composables/Emmiter';
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { Actions } from "@/store/enums/StoreEnums";
import AdicionarServicoModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoModal.vue";
import ServicoParalizadoModal from '@/views/agenda-virtual/components/modais/agendamentos/ServicoParalizadoModal.vue'
import AgendamentoModalOsAberta from '@/views/agenda-virtual/components/modais/agendamentos/AgendamentoModalOsAberta.vue'
import StatusServico from '@/views/agenda-virtual/components/modais/agendamentos/StatusServico.vue'
import ServicoPendente from '@/views/agenda-virtual/components/modais/agendamentos/ServicoPendente.vue'
import useUtilChanges from "@/composables/UtilChanges";
import { AgendaServico, AgendamentoServicosDms } from "@/models/AgendaModel";
import Orcamento from "../../modais/orcamentoComplementar/components/Orcamento.vue";
import { useAppointmentsDayBefore } from '../../../composables/AgendamentoDiaAnterior'
import {changeStoppedDayUpdate} from '@/views/agenda-virtual/utils/changeStopDayUtils'
import { generate } from '@/views/agenda-virtual/composables/chipSchedule'
import { configScheduleOption,Config } from '@/views/agenda-virtual/utils/ConfigScheduleUtils'

interface ExtendedAgendaServico extends AgendaServico {
  endWithout?: string;
}

export default defineComponent({
  name: "agenda-virtual-agendar-hyundai",
  components: {
    FullCalendar,
    VisualizarServicoModal,
    EditarServicoModal,
    AdicionarServicoPassanteModal,
    ContentLoader,
    AdicionarServicoModal,
    ServicoParalizadoModal,
    AgendamentoModalOsAberta,
    StatusServico,
    ServicoPendente,
    Orcamento
  },

  setup() {
    const store = useStore();
    const { showTimeAlert } = useAlert();
    const emitter = useEmitter();
    const { replacePlaca } = useUtilChanges();
    const { chipPrograming } = generate();
    const showModal = reactive({
      osAberta: false,
      status: false,
      pendente: false,
      paralisado: false
    })
    const animationTab = reactive({
      paralisado: false,
      osAberta: false,
      status: false,
      pendente: false,
      orcamento: false
    })    
    const servicoPedentes = ref([])
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const programaAgenda = userInfo.programaAgenda;
    const concessionariaInfo : any = ref({})
    const refCalendarHyundaiProgramacao:any = ref(null);
    const loading = ref(true);
    const loadingAgendamento = ref(false);
    const resources:any = ref([]);
    const tecnicos:any = ref([]);
    const consultores:any = ref([]);
    const eventInfo:any = ref();
    let events:any = ref([]);
    const dataMesAtual = moment().startOf("day").toISOString();
    const dateCalendarFilter = ref(dataMesAtual);
    const isUserActive = ref<any>(false);
    const isUserFastActive = ref<any>(false);
    const timeNow = ref(moment().format("HH:mm:ss"));
    const baseAgendamentosList= ref([])
    const veiculosRecepcao = ref(0)
    const countClickPrevNext = ref(0)
    const delayTimer = ref()
    const searchVehicles = ref('')
    const calendarOptions = ref({
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin ],
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: ""
        // right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      customButtons: {
        prev: {
          click: () => {
            if(!loadingAgendamento.value){
              refCalendarHyundaiProgramacao.value.getApi().prev();
              countClickPrevNext.value--
              controlaFiltro();
            }
          }
        },
        next: {
          click: () => {
            if(!loadingAgendamento.value){
              refCalendarHyundaiProgramacao.value.getApi().next();
              countClickPrevNext.value++
              controlaFiltro();
            }
          }
        },
      },
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
        
        startTime: '07:00', // a start time (10am in this example)
        endTime: '19:00', // an end time (6pm in this example)
      },
      resourceOrder: 'nome',
      slotMinTime: '07:00:00',
      slotMaxTime: '19:00:00',
      allDaySlot: false,
      locale: "pt-br",
      scrollTime: moment().subtract(2.25, "hours").format("HH:mm"),
      initialDate: TODAY,
      snapDuration: "00:15",

      initialView: 'resourceTimelineDay',
      eventColor: 'blue',
      eventBorderColor: 'blue',
      slotMinWidth: "130",
      contentHeight: "auto",
      eventBackgroundColor: 'blue',
      events: events.value,
      resources: resources.value,
      // eventDurationEditable: false,
      resourceAreaWidth: '10%',
      resourceAreaColumns: [
        // {
        //   field: 'num',
        //   headerContent: 'Nº',
        //   width: 10,
        //   group: true,
        // },
        {
          field: 'title',
          headerContent: 'Técnico',
          width: 100,
          cellContent: function (info) {
            let propriedades;
            if(info.fieldValue){
              propriedades = JSON.parse(info.fieldValue)
            } else if(info.groupValue){
              propriedades = JSON.parse(info.groupValue)
            }

            const html = `<div class="calendar-image-hyundai-prog"><img src="${propriedades.imagem}"><div class="calendar-field-hyundai-prog">${trataNome(propriedades.nome)}</div></div>`
            return { html };
          },
        },
        // {
        //   field: 'servico',
        //   headerContent: 'Serviço',
        //   width: 25,
        //   group: true,
        // },
        // {
        //   field: 'status',
        //   headerContent: '',
        //   width: 40,
        // },
      ],
      views: {
        resourceTimelineDay: { 
          buttonText: "dia",
        },
        // timeGridWeek: { buttonText: "semana" },
        // dayGridMonth: { buttonText: "mês" },
      },
      editable: !!programaAgenda,
      dayMaxEvents: true, // allow "more" link when too many events
      slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
      },
      eventDrop: (info) => editarDropEvent(info),
      dateClick: (info) => openAddPassanteModal(info),
      eventClick: (info) => {
        const auxInfo = info.event?.extendedProps?.allInfo;

        if(auxInfo.codTipoServico < 9) {
          openEditVisualizarServicoModal(info);
        } else {
          openVisualizarServicoModal(info);
        }
      },
      eventResize: (info) => editarResizeEvent(info),
      eventContent: function (info, createElement) {
        return chipPrograming(info)      
      }
    });
    const servicosParalizados = ref<AgendaServico[]>([])
    const refIntervals = reactive({
      baseAgendamento: 0,
      baseServicos: 0,
      timeNow: 0
    });
    const {appointmentsDayBefore,getAppointmentsDayBefore} = useAppointmentsDayBefore()
    const passanteInitial:any = ref({});
    const newServicoInitial:any = ref({});
    const baseOsAbertas:any = ref([]);
    const lastUpdatedServicos:any = ref(null);
    const refModalNew:any = ref(null);
    const appointmentsDayBeforeSelected = ref<AgendamentoServicosDms>()
    const throughService = ref(0)
    const initRequeModal = reactive({
      orcamento: true
    })
    const qtdTotal = reactive({
      orcamento: 0
    })
    const modeloAgendaSelect = ref(store.getters.modeloAgendaName)
    const configSchedule = ref<Config>(configScheduleOption(modeloAgendaSelect.value))

    async function horariosQuadro() {
      const concessionaria = store.getters.concessionariaSelectedInfo
      
      if(!(Object.keys(concessionaria).length === 0)){
        calendarOptions.value.slotMinTime = moment(concessionaria.horaAbertura, "H").format("HH:mm:ss")
        calendarOptions.value.slotMaxTime = moment(concessionaria.horaFechamento, "H").format("HH:mm:ss")
        calendarOptions.value.businessHours.startTime = moment(concessionaria.horaAbertura, "H").format("HH:mm")
        calendarOptions.value.businessHours.endTime = moment(concessionaria.horaFechamento, "H").format("HH:mm")
      }
    }

    // LIFECYCLE HOOKS
    onMounted(() => {
      horariosQuadro()
      refModalNew.value = new Modal(document.getElementById("kt_modal_add_event_hyundai-programacao"));

      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      
      if(store.getters.consultoresList.length > 0){
        consultores.value = store.getters.consultoresList;
      }

      if(store.getters.mecanicosList.length > 0){
        tecnicos.value = store.getters.mecanicosList;
        trataMecanicos();
      }

      if(store.getters.baseAgendamentosList.length > 0){
        const auxOSAbertas = store.getters.baseAgendamentosList
          .filter(agendamento => 
            agendamento.osaberta && 
            agendamento.servicosAgendados.length == 0 &&
            agendamento.placa || agendamento.chassi
          );
      
        baseOsAbertas.value = [...auxOSAbertas];
      }

      startPage();

      updateService();   

      timeUpdateService();
      
      // EMITTER HOOKS
      emitter.on("update-agenda-programacao", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      emitter.on("update-calendar", () => {
        controlaFiltro();
      });

      emitter.on("update-servicos-calendar", ({auxData, auxArrayAgendamento}) => {
        clearInterval(refIntervals.baseServicos);
        
        const dateFilter = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);
        const isSameDayFilterAndModal = auxData.isSame(dateFilter, "day");
        
        if(isSameDayFilterAndModal && auxData.isSame(moment(), "day")){
          loadingAgendamento.value = true;
          
          store.dispatch(Actions.SET_BASE_SERVICOS).then(() => {
            loadingAgendamento.value = false;

            refIntervals.baseServicos = setInterval(() => {
              store.dispatch(Actions.SET_BASE_SERVICOS);
            }, 90000);
          });
        } else if(isSameDayFilterAndModal){
          const auxResponseAgendamentos = auxArrayAgendamento.filter(agendamento => agendamento.osaberta && agendamento.servicosAgendados.length == 0);
          baseOsAbertas.value = [...auxResponseAgendamentos];

          buscaServicos(true);

          refIntervals.baseServicos = setInterval(() => {
            buscaServicos(false);
          }, 90000);
        }
      });

      refIntervals.timeNow = setInterval(() => {
        timeNow.value = moment().format("HH:mm:ss");        
      }, 1000);
    });

    store.watch(()=>store.getters.concessionariasList,()=> {
        if(store.getters.concessionariasList) horariosQuadro()
    })

    const modalShow = (idModal: string, nameModal: string) : void => {
      showModal[nameModal] = true;

      nextTick(() => {
          const modalElement = document.getElementById(idModal)

          if (modalElement) {
              const modal = new Modal(modalElement);
              modal.show();
          } else {
              console.error("Modal element not found!");
          }
      });  
    }
     
    onBeforeUnmount(() => {
      if(refIntervals.timeNow){
        clearInterval(refIntervals.timeNow);
      }
      clearAllSetInterval();

      emitter.off("update-agenda-programacao");
      emitter.off("agenda-clear-interval");
      emitter.off("update-calendar");
      emitter.off("update-servicos-calendar");
    });

    // WATCH HOOKS
    store.watch(() => store.getters.countBaseVeiculosOsAberta, () => {
      throughService.value = store.getters.countBaseVeiculosOsAberta
    }, { deep: true })

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.baseServicosList, () => {
      vehiclesPending()
      const dateFilter = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);
     
      if(dateFilter.isSame(moment(), "day") && store.getters.baseServicosList.length > 0){
        trataServicos(store.getters.baseServicosList);
      }    

      numberVehiclesReceived()      
    }, { deep: true });

    store.watch(() => store.getters.consultoresList, () => {
      consultores.value = store.getters.consultoresList;
    }, { deep: true });

    store.watch(() => store.getters.baseAgendamentosList, () => {
      const auxOSAbertas = store.getters.baseAgendamentosList.filter(agendamento => agendamento.osaberta && agendamento.servicosAgendados.length == 0);

      baseOsAbertas.value = [...auxOSAbertas];

      baseAgendamentosList.value = store.getters.baseAgendamentosList.filter(agendamento => agendamento.idagendamento || agendamento.placa).filter(agendamento => agendamento.servicosAgendados.length == 0).filter(agendamento => agendamento.osaberta);
        
      numberVehiclesReceived()
    }, { deep: true });

    store.watch(() => store.getters.mecanicosList, () => {
      tecnicos.value = store.getters.mecanicosList;

      trataMecanicos();
    }, { deep: true });

    store.watch(() => store.getters.servicoParalizadoList, () => {
      servicosParalizados.value = store.getters.servicoParalizadoList
    }, { deep: true });

    // FUNCTIONS
    function startPage(){
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
      inactivityTime();
      fastInactivityTime();

      servicosParalizados.value = store.getters.servicoParalizadoList
      baseAgendamentosList.value = store.getters.baseAgendamentosList.filter(agendamento => agendamento.idagendamento || agendamento.placa).filter(agendamento => agendamento.servicosAgendados.length == 0).filter(agendamento => agendamento.osaberta);
      numberVehiclesReceived()
      vehiclesPending()
      const auxDateSelected = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);
      const auxUpdateTimeServico = store.getters.refLastUpdatedTime.lastBaseServicos;
      const auxUpdateTimeAgendamento = store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      clearAllSetInterval();

      if(auxDateSelected.isSame(moment(), "day")){
        if(!auxUpdateTimeServico || moment().isAfter(auxUpdateTimeServico.clone().add(60, "seconds"))){
          store.dispatch(Actions.SET_BASE_SERVICOS).then(() => {
            loading.value = false;
          });
        } else {
          trataServicos(store.getters.baseServicosList);
        }

        refIntervals.baseServicos = setInterval(() => {
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 90000);

        if(!auxUpdateTimeAgendamento || moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))){
          // store.dispatch(Actions.SET_BASE_AGENDAMENTOS).then(() => {
          //   loadingAgendamento.value = false;
          // });
        } else {
          const auxOSAbertas = store.getters.baseAgendamentosList.filter(agendamento => agendamento.osaberta && agendamento.servicosAgendados.length == 0);
          baseOsAbertas.value = [...auxOSAbertas];
        }
      } else {
        if(!lastUpdatedServicos.value || moment().isAfter(lastUpdatedServicos.value.clone().add(60, "seconds"))) {
          controlaFiltro();
        }
      }
    };

    function clearAllSetInterval(){
      clearInterval(refIntervals.baseServicos);
      clearInterval(refIntervals.baseAgendamento);
    }

    function changeCalendarDate(date){
      if(date){
        refCalendarHyundaiProgramacao.value.getApi().gotoDate(date);

        controlaFiltro();
      }
    };

    function numberVehiclesReceived() : void { 
      veiculosRecepcao.value = store.getters.baseVeiculosRecepcao.length
    }

    function vehiclesPending(){
      const tiposServico = [9,10,11,12]
      servicoPedentes.value = store.getters.baseServicosList.filter(servico => servico.status == 1 && !tiposServico.includes(servico.codTipoServico))
    }

    async function trataMecanicos(){
      while(resources.value.length) {
        resources.value.pop();
      }

      tecnicos.value.forEach((tecnico, index) => {
        const resource = {
          id: tecnico.codMecanico.toString(),
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          nome: tecnico.nome,
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Plano"
        };

        resources.value.push(resource);
      });
    };

    function clearCalendar(){
      const eventsCalendar = refCalendarHyundaiProgramacao.value?.getApi()?.getEvents() ?? [];
      eventsCalendar.forEach(event => {
        event.remove();
      });
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    function atualizaAgendamentos(){
      const dateFilter = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);

      if(dateFilter.isSame(moment(), "day")){
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
      }
    }

    async function updateAgendamentos(){
      clearCalendar()
      buscaServicos(true)
    }

    async function controlaFiltro(showLoading = true) {
      const dateFilter = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);

      clearInterval(refIntervals.baseServicos);

      if(dateFilter.isSame(moment(), "day")){
        if(showLoading){
          loading.value = true;
          loadingAgendamento.value = true;
        } 
        
        store.dispatch(Actions.SET_BASE_SERVICOS).then(() => {
          loadingAgendamento.value = false;
        });

        updateService()       
      } else {
        buscaServicos(showLoading);
        buscaAgendamentos(showLoading);

        refIntervals.baseServicos = setInterval(() => {
          buscaServicos(false);
          changeStoppedDayUpdate(countClickPrevNext.value,refCalendarHyundaiProgramacao.value,controlaFiltro)
        }, 90000);
      }
    };

    async function buscaAgendamentos(showLoading){
      const diferencaDias:any = moment(refCalendarHyundaiProgramacao.value.getApi().getDate()).startOf('day').diff(moment().startOf('day'), "days", true).toFixed(0) ?? 0;

      try{
        if(showLoading){
          baseOsAbertas.value = [];
          loadingAgendamento.value = true;
        }

        let auxResponseAgendamentos = await getBaseAgendamento(concessionariaInfo.value.codConcessionaria, diferencaDias).then(data => data).catch((e) => {
          return [];
        });
        auxResponseAgendamentos = auxResponseAgendamentos.filter(agendamento => agendamento.osaberta && agendamento.servicosAgendados.length == 0);

        baseOsAbertas.value = [...auxResponseAgendamentos];
      } finally {
        if(showLoading){
          loadingAgendamento.value = false;
        }
      }
    };

    
    function newTask(agendamento) {
      if(programaAgenda == 0) return 
      newServicoInitial.value = {
        codAgendamento: agendamento.idagendamento,
        detalhes: agendamento.obs,
        taskType: "",
        cliente: agendamento.cliente,
        placa: agendamento.placa,
        chassi: agendamento.chassi,
        taskHourEntrega: "",
        taskHourRecepcao: agendamento.horaagendada,
        formType: "New",
        taskEndHour: "",
        taskDateTratada: agendamento.dataagendamento,
        taskDate: moment(agendamento.dataagendamento, "DD/MM/YYYY"),
        taskStartHour: agendamento.horaagendada,
        cnpjConcessionaria: concessionariaInfo.value.cnpj,
        codConcessionaria: concessionariaInfo.value.codConcessionaria,
        dataAgendamento: `${agendamento.dataagendamento} ${agendamento.horaagendada}`,
        marca: agendamento.marca,
        modelo: agendamento.modelo,
        consultorAgendado: agendamento.consultorcodigo ?? agendamento.consultor_agendado,
        consultorAgendadoTratado: agendamento.consultor_agendado,
        osAberta: agendamento.osaberta
      }
   
        appointmentsDayBeforeSelected.value = agendamento
  
      
      refModalNew.value.show();
    };

    async function buscaServicos(showLoading){
      const dateFilter = moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined);
      lastUpdatedServicos.value = dateFilter.clone();

      try{
        if(showLoading){
          loading.value = true;
        }

        const response = await getServicosAgendados(concessionariaInfo.value.codConcessionaria, dateFilter.format("YYYY-MM-DD"));

        if(response.length > 0){
          trataServicos(response);
        }
      } finally {
        loading.value = false;
      }
    };

    async function trataServicos(servicos: ExtendedAgendaServico[]){
      try {
        clearCalendar()

        servicos.forEach((servico) => {
          const eventConfig = config.events[servico.codTipoServico - 1];
          let newEvent = {};

          if(servico.tempoAdicional){
            const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
              servico.endWithout = end.subtract(servico.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
          } else {
            servico.endWithout = servico.dataInicioServico.replace(" ", "T");
          } 
          
          if(servico.codTipoServico < 9){

            let auxNomeConsultor = consultores.value.find(consultor => removeAcentos(consultor.intConsultor, true) == removeAcentos(servico.consultorAgendado, true))?.nomeTratado;
            if(!auxNomeConsultor){
              auxNomeConsultor = consultores.value.find(consultor => removeAcentos(consultor.nome, true) == removeAcentos(servico.consultorAgendado, true))?.nomeTratado;
            }

            newEvent = {
              id: servico.codServico,
              resourceId: servico.codTecnico.toString(),
              title: eventConfig.title,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                aguarda: servico.aguarda == 1 ? true : false,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                cliente: trataNome(servico.cliente),
                consultor: servico.consultorAgendado,
                consultorNome: auxNomeConsultor ?? "",
                recepcao: servico.dataRecepcao.replace(" ", "T"), 
                entrega: servico.dataEntrega.replace(" ", "T"),
                placa: servico.placa,
                lateTime: servico.lateTime,
                chassi: servico.chassi,
                osAberta: servico.osAberta,
                prisma: servico.prisma ?? '',
                modelo: servico.modelo ?? '',
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                bgColor: eventConfig.extendedProps.bgColor,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 9){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                placa: servico.placa,
                chassi: servico.chassi,
                osAberta: servico.osAberta,
                prisma: servico.prisma ?? '',
                modelo: servico.modelo ?? '',
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 10 || servico.codTipoServico == 11){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              start:  moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
              end: moment(servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                placa: servico.placa,
                chassi: servico.chassi,
                osAberta: servico.osAberta,
                prisma: servico.prisma ?? '',
                modelo: servico.modelo ?? '',
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 12){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: servico.observacao,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                placa: servico.placa,
                chassi: servico.chassi,
                osAberta: servico.osAberta,
                prisma: servico.prisma ?? '',
                modelo: servico.modelo ?? '',
                codTipoServico: servico.codTipoServico,
                tempoAdicional: servico.tempoAdicional,
                footerTextColor: eventConfig.extendedProps.footerTextColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          }

          refCalendarHyundaiProgramacao.value.getApi().addEvent(newEvent);
        });

        const calendarioDataAtual = moment().isSame(moment(refCalendarHyundaiProgramacao.value?.getApi()?.getDate() ?? undefined), 'day');
        if(calendarioDataAtual && !isUserActive.value){
          refCalendarHyundaiProgramacao.value?.getApi()?.scrollToTime(moment().subtract(2.25, "hours").format("HH:mm"));
        }
      } catch (e) {
        console.error(e);
        // showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }
    }

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      } 
      let elem:any = document.querySelector("#card-calendar-hyundai-programacao");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    async function editarDropEvent(dropInfo){
      if(document.fullscreenElement){
        document.exitFullscreen();
      }

      const allInfo = dropInfo.event.extendedProps.allInfo;
      if(allInfo.codTipoServico == 9 && dropInfo.newResource && dropInfo.oldResource && (dropInfo.newResource.id != dropInfo.oldResource.id)){
        dropInfo.revert();
        return;
      }

      if(allInfo.codTipoServico == 10 || allInfo.codTipoServico == 11){
        const eventConfig = config.events[allInfo.codTipoServico - 1];

        dropInfo.revert();
        Swal.fire({
          title: `Não é possível alterar o horário do agendamento "${eventConfig.title}"`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
          },
        })
        return;
      }

      let idResource;
      if(dropInfo.newResource){
        idResource = dropInfo.newResource.id.split("_")[0];
      } else {
        idResource = allInfo.codTecnico;
      }
      const postData = {
        dataInicioServico: moment(dropInfo.event.start).format("YYYY-MM-DD HH:mm:ss"),
        dataFinalServico: moment(dropInfo.event.end).format("YYYY-MM-DD HH:mm:ss"),
        codTecnico: idResource,
      };

      try {
        const response = await editAgendamento(dropInfo.event.id, postData);

        if(response.ocupado){
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(dropInfo.event.start).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          dropInfo.revert();
          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          })

          return;
        }

        controlaFiltro(false);
        showTimeAlert("Alteração realizada com sucesso!");
      }catch(e){
        console.error(e);
        dropInfo.revert();
        showTimeAlert("Não foi possível completar a solicitação", "error");
      }
    };

    async function editarResizeEvent(resizeInfo){
      if(document.fullscreenElement){
        document.exitFullscreen();
      }

      const allInfo = resizeInfo.event.extendedProps.allInfo;
      if(allInfo.codTipoServico == 9 && resizeInfo.newResource && resizeInfo.oldResource && (resizeInfo.newResource.id != resizeInfo.oldResource.id)){
        resizeInfo.revert();
        return;
      }

      if(allInfo.codTipoServico == 10 || allInfo.codTipoServico == 11){
        const eventConfig = config.events[allInfo.codTipoServico - 1];

        resizeInfo.revert();
        Swal.fire({
          title: `Não é possível alterar o horário do agendamento "${eventConfig.title}"`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
          },
        })
        return;
      }

      let idResource;
      if(resizeInfo.newResource){
        idResource = resizeInfo.newResource.id.split("_")[0];
      } else {
        idResource = allInfo.codTecnico;
      }
      const postData = {
        dataInicioServico: moment(resizeInfo.event.start).format("YYYY-MM-DD HH:mm:ss"),
        dataFinalServico: moment(resizeInfo.event.end).format("YYYY-MM-DD HH:mm:ss"),
        codTecnico: idResource,
      };

      try {
        const response = await editAgendamento(resizeInfo.event.id, postData);

        if(response.ocupado){
          let htmlOcupados = "<ul>";
          await response.horariosOcupados.forEach(horario => {
            const eventConfig = config.events[horario.codTipoServico - 1];
            const dataTratada = moment(resizeInfo.event.start).format("YYYY-MM-DD");
            const startTime = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const endTime = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

            let startDate = "";
            let endDate = "";

            if (horario.codTipoServico == 10 || horario.codTipoServico == 11){
              startDate = moment(horario.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
              endDate = moment(horario.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
            } else {
              startDate = moment(`${dataTratada} ${startTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
              endDate = moment(`${dataTratada} ${endTime}`, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
            }

            htmlOcupados += `<li>${startDate} - ${endDate} (${eventConfig.title})</li>`
          });
          htmlOcupados += "</ul>";

          resizeInfo.revert();
          Swal.fire({
            title: response.mensagem,
            html: `<h4>Horário(s) ocupado(s):</h4> ${htmlOcupados}`,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-flex background_btn_1_opct text-white",
            },
          })

          return;
        }

        controlaFiltro(false);
        showTimeAlert("Alteração realizada com sucesso!");
      }catch(e){
        console.error(e);
        resizeInfo.revert();
        showTimeAlert("Não foi possível completar a solicitação", "error");
      }
    };

    function openVisualizarServicoModal(info) {
      if(document.fullscreenElement){
        document.exitFullscreen();
      } 
      eventInfo.value = info.event?.extendedProps?.allInfo;
      const modal = new Modal(document.getElementById("kt_modal_event_info_1"));
      modal.show();
    };

    function openEditVisualizarServicoModal(info) {
      if(document.fullscreenElement){
        document.exitFullscreen();
      } 
      eventInfo.value = info.event?.extendedProps?.allInfo;
      const modal = new Modal(document.getElementById("kt_modal_event_edit_info_1"));
      modal.show();
    };

    function openAddPassanteModal(info) {
      if(programaAgenda != 1){
        return;
      }

      if(document.fullscreenElement){
        document.exitFullscreen();
      };
      
      const infoTratada = {
        formType: "New",
        tecnico: Number(info.resource.id),
        marca: concessionariaInfo.value.marca,
        cnpjConcessionaria: concessionariaInfo.value.cnpj,
        codConcessionaria: concessionariaInfo.value.codConcessionaria,
        taskDateTratada:  moment(info.date , "YYYY-MM-DD HH:mm:ss"),
        taskDate:  moment(info.date , "YYYY-MM-DD HH:mm:ss"),
        taskStartHour: moment(info.date).format("HH:mm"),
      };

      passanteInitial.value = infoTratada;

      const modal = new Modal(document.getElementById("kt_modal_add_without_ref_event_calendar-hyundai"));
      modal.show();
    };

    function inactivityTime() {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 60000)
      }
    };

    function fastInactivityTime() {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserFastActive.value = false;
      }
      function resetTimer() {
        isUserFastActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000)
      }
    };

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}` 

      return nomeTratado;
    }

    const totalBolsoes = () => {
      orcamentoComplementarTotal(concessionariaInfo.value.codConcessionaria)
        .then((response) => qtdTotal.orcamento = response.totalOrcamento )
    }

    const searchservice = () => {

      const calendarApi = refCalendarHyundaiProgramacao.value.getApi()
      calendarApi.removeAllEvents()
      const service = store.getters.baseServicosList

      if(store.getters.baseServicosList.length > 0){

        const serviceFilter = service.filter((service) => {
            if (!searchVehicles.value) {
                return true;
            }

            if (service.placa && service.codServico > 9) {
                return service.placa.toUpperCase().includes(searchVehicles.value.toUpperCase());
            }

            return false;
        });

        trataServicos(serviceFilter)
      }      
    }

    const filterData = () => {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function() {
        searchservice()
      }, 300);
    }

    const updateService = async  () => {       
       store.dispatch(Actions.SET_OS_ABERTA).finally(() => {
         getAppointmentsDayBefore()  
       });
       store.dispatch(Actions.SET_BASE_AGENDAMENTOS).finally(() => {
        store.dispatch(Actions.SET_VEICULO_RECEPCAO)   
       })
      store.dispatch(Actions.SET_SERVICOS_PARALIZADOS);
      totalBolsoes()
    }

    const timeUpdateService = () => {
      setInterval(() => {
          store.dispatch(Actions.SET_VEICULO_RECEPCAO);
          totalBolsoes()
          store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
      }, 300000);

      setInterval(() => {
        store.dispatch(Actions.SET_OS_ABERTA)
      }, 200000);
    }

    return {
      tecnicos,
      calendarOptions,
      events,
      refCalendarHyundaiProgramacao,
      resources,
      eventInfo,
      calendarFullscreen,
      loading,
      loadingAgendamento,
      concessionariaInfo,
      codNivel,
      controlaFiltro,
      isUserFastActive,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      timeNow,
      ptBr,
      changeCalendarDate,
      dateCalendarFilter,
      clearCalendar,
      passanteInitial,
      baseOsAbertas,
      newServicoInitial,
      atualizaAgendamentos,
      newTask,
      replacePlaca,
      programaAgenda,
      animationTab,
      servicosParalizados,
      baseAgendamentosList,
      veiculosRecepcao,
      servicoPedentes,
      updateAgendamentos,
      appointmentsDayBefore,
      appointmentsDayBeforeSelected,
      throughService,
      initRequeModal,
      qtdTotal,
      configSchedule,
      searchVehicles,
      filterData,
      showModal,
      modalShow
    };
  },
});


import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import useAlert from "@/composables/Alert";
import { ContentLoader } from 'vue-content-loader';
import { useStore } from "vuex";
import { AgendaServico } from "@/models/AgendaModel"
import { ConcessionariaModel } from "@/models/ConcessionariaModel";
import { Modal } from "bootstrap";
import EditarServicoModalParalisadoHyundai from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModalParalisadoHyundai.vue";
import AuthService from "@/services/AuthService";
import { Actions } from "@/store/enums/StoreEnums";

interface AgendaServicoExtendido extends AgendaServico {
  clienteTratado?: string;
  consultorTratado?: string;
  data?: string;
}

export default defineComponent({
  name: "ServicoParalizadoModal",
  components: {
    ContentLoader,
    EditarServicoModalParalisadoHyundai
  },
  emits: ['success'],
  setup(props, { emit }) {
    const store = useStore();
    const { showTimeAlert } = useAlert();
    const agendamentos = ref<AgendaServicoExtendido[]>([]);
    const loading = ref(true);
    const tabTitle = ref<string[]>([])
    const refModalNew = ref<Modal | null>(null);
    const activeName = ref('')
    const initialForm = ref()
    const concessionariaInfo = ref<ConcessionariaModel>(store.getters.concessionariaSelectedInfo)
    const userInfo = AuthService.getUsuarioAuth();
    const programaAgenda = userInfo.programaAgenda;

    function agruparPorMotivo(arrayOriginal: AgendaServicoExtendido[]) {
    
      const motivosUnicos = Array.from(new Set(arrayOriginal.map(item => item.motivoParada)));

      tabTitle.value = motivosUnicos.filter(motivo => motivo) as string[];
      if (tabTitle.value.length > 0) {
        activeName.value = tabTitle.value[0]
      }else{
        activeName.value = 'causeless'
      }
    }

    onMounted(() => {
      getAgendamentos(store.getters.servicoParalizadoList);
      refModalNew.value = new Modal(document.getElementById("kt_modal_event_edit_info_paralisado"));
    })

    store.watch(() => store.getters.servicoParalizadoList, () => {
      getAgendamentos(store.getters.servicoParalizadoList);
    }, { deep: true });

    function trataNome(nome) {
      if (!nome || nome == "undefined" || nome == undefined) {
        return "";
      }

      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]} ${arrayNomes[arrayNomes.length - 1].substring(0, 1)}.`

      return nomeTratado;
    }

    function closeModalServicosAgendados() {
      const modal = document.getElementById("kt_modal_select_agendamento_realizados");
      hideModal(modal);
    };

    function getAgendamentos(refBase: AgendaServico[]) {
      try {
        loading.value = true;
        agendamentos.value = [];
        agendamentos.value = refBase.map(itemAgendamento => {
          const clienteTratado = itemAgendamento.cliente ? trataNome(itemAgendamento.cliente) : "";
          const consultorTratado = itemAgendamento.consultorAgendado ? trataNome(itemAgendamento.consultorAgendado) : "";

          const agendamentoAtualizado: AgendaServicoExtendido = {
            ...itemAgendamento,
            clienteTratado,
            consultorTratado,
            data: `${itemAgendamento.dataRecepcao} `,
            motivoParada: itemAgendamento.motivoParada ?? 'Veículo paralisado'
          };

          return agendamentoAtualizado;
        });
        loading.value = false;
      } catch (e) {
        console.error(e);
        showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }

       agruparPorMotivo(agendamentos.value)
    }

    function atualizarServicoParalizado() {
      loading.value = true
      store.dispatch(Actions.SET_SERVICOS_PARALIZADOS).then(() => loading.value = false);
      emit('success')
    };

    const handleClick = (tab, event: Event) => {
      // console.log(tab, event)
    }

    function newTask(agendamento) {

      initialForm.value = {
        ...agendamento,
        cnpjConcessionaria: concessionariaInfo.value.cnpj,
        codConcessionaria: concessionariaInfo.value.codConcessionaria,
      }

      refModalNew.value.show();
    };
    
    return {
      closeModalServicosAgendados,
      agendamentos,
      loading,
      handleClick,
      activeName,
      tabTitle,
      newTask,
      programaAgenda,
      atualizarServicoParalizado,
      initialForm,
      concessionariaInfo,
      userInfo
    };
  },
});

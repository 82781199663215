
import FiltroDataModal from "@/views/agenda-virtual/components/modais/agendamentos/components/FiltroDataModal.vue";
import AdicionarServicoModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoModal.vue";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import AdicionarServicoPassanteModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoPassanteModal.vue";

import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert";
import { ContentLoader } from 'vue-content-loader';
import useEmitter from '@/composables/Emmiter';
import { useStore } from "vuex";
import { AgendaServico } from "@/models/AgendaModel";

interface AgendaServicoExtendido extends AgendaServico {
  consultorTratado?: string;
  progressoOS?: string;
  clienteTratado?: string;
  data?: string
}

export default defineComponent({
  name: "new-event-modal",
  components: {
    FiltroDataModal,
    AdicionarServicoModal,
    AdicionarServicoPassanteModal,
    VisualizarServicoModal,
    EditarServicoModal,
    ContentLoader,
  },

  emits: ['success'],

  setup(props, { emit }) {
    const store = useStore();
    const eventInfo: any = ref();
    const { showTimeAlert } = useAlert();
    const agendamentos = ref<AgendaServicoExtendido[]>([]);
    const loading = ref(true);
    const dataMesAtual = moment().startOf("day");
    const dataMesAtualFormatada = dataMesAtual.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de");
    const dataFiltrada = ref(dataMesAtualFormatada);
    const emitter = useEmitter();

    // LifeCycles
    onMounted(() => {
      getAgendamentos(store.getters.baseServicosList);

    });

    store.watch(() => store.getters.baseServicosList, () => {
      getAgendamentos(store.getters.baseServicosList);
    }, { deep: true });

    emitter.on("agenda-loading", () => {
      loading.value = true;
    });

    function trataNome(nome) {
      if (!nome || nome == "undefined" || nome == undefined) {
        return "";
      }

      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]} ${arrayNomes[arrayNomes.length - 1].substring(0, 1)}.`

      return nomeTratado;
    }

    function closeModalServicosAgendados() {
      const modal = document.getElementById("kt_modal_select_agendamento_realizados");
      hideModal(modal);
    };

    async function emiteSuccess() {

    };

    function getAgendamentos(refBase: AgendaServico[]) {
      try {
        loading.value = true;
        agendamentos.value = refBase.map(itemAgendamento => {
          const clienteTratado = itemAgendamento.cliente ? trataNome(itemAgendamento.cliente) : "";
          const consultorTratado = itemAgendamento.consultorAgendado ? trataNome(itemAgendamento.consultorAgendado) : "";

          const agendamentoAtualizado = {
            ...itemAgendamento,
            clienteTratado,
            consultorTratado,
            data: `${itemAgendamento.dataRecepcao} `,
          };

          return agendamentoAtualizado;
        });

        const tipoServico = [9,10,11,12]

        agendamentos.value = agendamentos.value.filter(servico => servico.status == 1 && !tipoServico.includes(servico.codTipoServico))
        loading.value = false;
      } catch (e) {
        console.error(e);
        showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }

    }

    return {
      closeModalServicosAgendados,
      eventInfo,
      moment,
      agendamentos,
      emiteSuccess,
      dataFiltrada,
      loading
    };
  },
});
